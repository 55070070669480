import React from "react";

import { Link } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";

const Training = ({ location }) => (
  <Layout location={location}>
    <SEO title="Training" description="Das Training des kkm" />
    <article>
      <h1>Training</h1>
      <p>
        Unser Training findet grundsätzlich in unserer Heimspielstätte, dem{" "}
        <a href="https://www.google.com/maps/place/Kombinat/@49.492225,8.4727232,15z/data=!4m5!3m4!1s0x0:0x5f4bcd9e8714a50c!8m2!3d49.492225!4d8.4727232">
          Kombinat
        </a>
        , statt.
      </p>

      {/* grid with 3 columns */}
      <div class="training-grid">
        {/* HEADER */}
        <div>
          <strong>Tag</strong>
        </div>
        <div>
          <strong>Offenes Training Amateure</strong>
        </div>
        <div>
          <strong>Offenes Training Fortgeschrittene</strong>
        </div>
        {/* CONTENT ROW 1 */}
        <div>Montag</div>
        <div>18:30 - 19:30 Uhr (DYP-Warmup)</div>
        <div>---</div>
        {/* CONTENT ROW 2 */}
        <div>Donnerstag</div>
        <div>18:00 - 19:30 Uhr (Technik-Training)</div>
        <div>19:30 - 21:00 Uhr (Technik-Training)</div>
        {/* CONTENT ROW 3 */}
        <div>Ausweichtermin Mittwoch (falls Donnerstag nicht möglich)</div>
        <div>18:00 - 19:30 Uhr (Technik-Training)</div>
        <div>19:30 - 21:00 Uhr (Technik-Training)</div>
      </div>

      <p>
        Donnerstags trainieren die aktiven Mitglieder für den Ligabetrieb. Hier
        steht die Schulung der spielerischen Fähigkeiten im Vordergrund. Die
        Teilnahme am Training ist für aktive Mitglieder kostenfrei. Alle anderen
        können gegen einen Unkostenbeitrag in Höhe von 2,-€ pro Person
        teilnehmen. Montags sind die Tische generell offen und jeder kann
        kostenfrei spielen. Beim anschließenden Monster-DYP sind unsere Spieler
        zahlreich regelmäßig vertreten und spielen um die oberen Plätze mit.
      </p>
      <p>
        Während des Trainings gilt unsere{" "}
        <Link to="/dokumente">Trainingsordnung</Link>. Nichteinhalten der
        Trainingsordnung kann zum Ausschluss vom Training führen.
      </p>
    </article>
  </Layout>
);

export default Training;
